import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
// import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public apiService: ApiServiceService, ) { }
  // private spinner: NgxSpinnerService

  // showSuccess(message, title) {
  //   this.toastr.success(message, title)
  // }
  // showError(message, title) {
  //   this.toastr.error(message, title)
  // }

  // showInfo(message, title) {
  //   this.toastr.info(message, title)
  // }

  // showWarning(message, title) {
  //   this.toastr.warning(message, title)
  // }

  // showLoader() {
  //   this.spinner.show();
  // }

  // dismissLoader() {
  //   this.spinner.hide();
  // }

  CalculateHourMinute(outEntryTime) {
    var CurrentTime = new Date();
    let diffMs = CurrentTime.valueOf() - outEntryTime.valueOf();
    let hours = Math.floor((diffMs % 86400000) / 3600000); // hours
    var strHours = (hours < 10) ? ('0' + hours).slice(-2) : hours;

    let minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var strMinuts = (minutes < 10) ? ('0' + minutes).slice(-2) : minutes;
    return strHours + ":" + strMinuts;
  }

  getOrdinalString(number) {
    if (number > 3 && number < 21) return 'th';
    switch (number % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  convertDateToAnotherOffsetTime(offsetTime) {
    var date = new Date();
    var beforePoint = offsetTime.toString().split(".")[0];
    var afterPoint = offsetTime.toString().split(".")[1];
    if (afterPoint) {
      afterPoint = (afterPoint * 100) / 60;
      offsetTime = beforePoint + '.' + afterPoint;
    }

    var utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    let hours = (new Date(utcTime + (3600000 * offsetTime)).getHours() < 10) ? ('0' + new Date(utcTime + (3600000 * offsetTime)).getHours()).slice(-2) : new Date(utcTime + (3600000 * offsetTime)).getHours() // hours
    let minutes = (new Date(utcTime + (3600000 * offsetTime)).getMinutes() < 10) ? ('0' + new Date(utcTime + (3600000 * offsetTime)).getMinutes()).slice(-2) : new Date(utcTime + (3600000 * offsetTime)).getMinutes(); // minutes
    return hours + ":" + minutes;
  }

  convertTimeTwoDigitNumber(date) {
    var utcTime = new Date(date);
    let hours = (utcTime.getHours() < 10) ? ('0' + utcTime.getHours()).slice(-2) : utcTime.getHours() // hours
    let minutes = (utcTime.getMinutes() < 10) ? ('0' + utcTime.getMinutes()).slice(-2) : utcTime.getMinutes() // minutes
    return hours + ":" + minutes;
  }


  timeConvert24to12(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[0] = (time[0] < 10) ? ('0' + time[0]).slice(-2) : time[0] // hours
    }
    return time.join(''); // return adjusted time or original string
  }
}