<div class="top-navigation text-center">
  <button class="btn btn-primary"><span></span></button>
  <div class="mobile-menu row mx-0" style="display: none">
    <ul class="list-unstyled">
      <li><a href="#weather">Weather / Clock</a></li>
      <li><a href="#events">Events</a></li>
      <li><a href="#deliveries">Deliveries</a></li>
      <li>
        <a href="#feedback">360<sup>0</sup> Feadback</a>
      </li>
      <li><a href="#celebration">Celebration</a></li>
      <li><a href="#leavetoday">On Leave Today</a></li>
      <li><a href="#outoffice">Out of Office</a></li>
    </ul>
  </div>
</div>
<div class="main-screen container-fluid">
  <div class="row">
    <div class="col-md-9">
      <div class="left-sec pr-0" style="width: 100%; max-width: 100%">
        <div
          class="top-header row mx-0 align-items-center justify-content-start"
          id="weather"
        >
          <div class="cloud-wrap"></div>
          <div
            class="col-12 col-sm-4 col-md-3 col-lg-2 firstcol"
            *ngIf="CurrentWeather"
          >
            <div id="weather-app" class="weather-app">
              <i
                id="weather-icon"
                class="wi wi-fw weather-icon wi-day-sunny"
              ></i>
              <div class="date-time">
                <div id="date" class="date">
                  <div id="day" class="day">{{ CurrentDayName }}</div>
                  <div id="monDate" class="monDate">
                    {{ CurrentMonthName }} {{ CurrentDate }}
                  </div>
                  <div class="weather-temp">
                    <span id="temperature" class="temperature"
                      >{{ CurrentWeather.temp.current }} </span
                    ><sup>C</sup>
                  </div>
                </div>
              </div>
              <div class="location-weather">
                <div class="weather-status">
                  <span id="city" class="city">{{
                    CurrentWeather.CityName
                  }}</span
                  ><span class="ml-1">Gujarat</span>
                  <div id="forcast" class="forcast" data-weather="">
                    <!-- <img alt="Birtday" src="http://openweathermap.org/img/wn/50d@2x.png">  -->
                    {{ CurrentWeather.weather.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex" *ngIf="VerveDashboardClockList && VerveDashboardClockList.length > 0"> -->
          <div
            class="col-12 col-sm-4 col-md-3 col-lg-2 px-sm-0 text-center"
            *ngFor="let clock of VerveDashboardClockList; let i = index"
          >
            <div class="time-box">
              <span class="d-block time">{{ clock.Time }}</span>
              <span class="d-block location">{{ clock.CityName }}</span>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="middle-content">
          <div
            class="event-section d-lg-flex"
            id="events"
            *ngIf="UpcommingEventList && UpcommingEventList.length > 0"
          >
            <div class="title">
              <h2>Events</h2>
            </div>
            <div class="event-listing" style="overflow-y: hidden">
              <owl-carousel-o
                *ngIf="UpcommingEventList.length > 3"
                [options]="customOptions"
              >
                <ng-container *ngFor="let event of UpcommingEventList">
                  <ng-template carouselSlide [id]="event.Id">
                    <div class="col-12">
                      <div class="event-box">
                        <h4>
                          <span
                            class="blue-tooltip"
                            mat-raised-button
                            matTooltip="{{ event.EventTitle }}"
                            aria-label="Button that displays a tooltip when focused or hovered over"
                          >
                            {{ event.EventTitle }}
                          </span>
                        </h4>
                        <ul class="list-unstyled">
                          <li class="date">
                            {{ event.CurrentDayName }}, {{ event.CurrentDate
                            }}<sup>{{ event.OrdinalString }}</sup>
                            {{ event.CurrentMonthName }}
                          </li>
                          <li class="timer">
                            {{ event.StartTime
                            }}<sub>{{ event.StartTimeA }}</sub> to
                            {{ event.EndTime }}<sub>{{ event.EndTimeA }}</sub>
                          </li>
                          <li class="location">
                            <span
                              class="blue-tooltip"
                              mat-raised-button
                              matTooltip="{{ event.Location }}"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                            >
                              {{ event.Location }}
                            </span>
                          </li>
                          <li class="users">
                            <!-- <div class="carousel slide carousel-fade" data-ride="carousel">
                            <div class="carousel-inner">
                              <div class="carousel-item {{employee.isActive}}"
                                *ngFor="let employee of event.Attendees">
                                <div class="media">
                                  <span>{{employee.Employee_Name}}</span>
                                </div>
                              </div>
                            </div>
                          </div> -->

                            <div
                              class="carousel slide carousel-fade"
                              data-ride="carousel"
                            >
                              <div class="carousel-inner">
                                <div class="media">
                                  <span
                                    matTooltipClass="example-tooltip-red"
                                    class="blue-tooltip"
                                    mat-raised-button
                                    matTooltip="{{ event.Employeelist }}"
                                    aria-label="Button that displays a tooltip when focused or hovered over"
                                  >
                                    {{ event.Attendees[0].Employee_Name }}
                                    <span *ngIf="event.Attendees.length > 1"
                                      >+{{ event.Attendees.length - 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
              <owl-carousel-o
                *ngIf="UpcommingEventList.length <= 3"
                [options]="customNoslideOptions"
              >
                <ng-container *ngFor="let event of UpcommingEventList">
                  <ng-template carouselSlide [id]="event.Id">
                    <div class="col-12">
                      <div class="event-box">
                        <h4>
                          <span
                            class="blue-tooltip"
                            mat-raised-button
                            matTooltip="{{ event.EventTitle }}"
                            aria-label="Button that displays a tooltip when focused or hovered over"
                          >
                            {{ event.EventTitle }}
                          </span>
                        </h4>
                        <ul class="list-unstyled">
                          <li class="date">
                            {{ event.CurrentDayName }}, {{ event.CurrentDate
                            }}<sup>{{ event.OrdinalString }}</sup>
                            {{ event.CurrentMonthName }}
                          </li>
                          <li class="timer">
                            {{ event.StartTime
                            }}<sub>{{ event.StartTimeA }}</sub> to
                            {{ event.EndTime }}<sub>{{ event.EndTimeA }}</sub>
                          </li>
                          <li class="location">
                            <span
                              class="blue-tooltip"
                              mat-raised-button
                              matTooltip="{{ event.Location }}"
                              aria-label="Button that displays a tooltip when focused or hovered over"
                            >
                              {{ event.Location }}
                            </span>
                          </li>
                          <li class="users">
                            <div
                              class="carousel slide carousel-fade"
                              data-ride="carousel"
                            >
                              <div class="carousel-inner">
                                <div class="media">
                                  <span
                                    matTooltipClass="example-tooltip-red"
                                    class="blue-tooltip"
                                    mat-raised-button
                                    matTooltip="{{ event.Employeelist }}"
                                    aria-label="Button that displays a tooltip when focused or hovered over"
                                  >
                                    {{ event.Attendees[0].Employee_Name }}
                                    <span *ngIf="event.Attendees.length > 1"
                                      >+{{ event.Attendees.length - 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="carousel slide carousel-fade" data-ride="carousel">
                              <div class="carousel-inner">
                                <div class="carousel-item {{employee.isActive}}" *ngFor="let employee of event.Attendees">
                                  <div class="media">
                                    <span>{{employee.Employee_Name}}</span>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>

          <h4 class="pt-3" *ngIf="OutOfOfficeStatusEmployeeList.length > 0">
            Out Of Office
          </h4>
          <div class="deliveries-section d-lg-flex" id="deliveries">
            <div class="deliveries-listing">
              <div class="list-unstyled">
                <owl-carousel-o
                  [options]="
                    OutOfOfficeStatusEmployeeList.length > 3
                      ? customOptions
                      : customNoslideOptions
                  "
                >
                  <ng-template
                    id="slide-item"
                    carouselSlide
                    *ngFor="
                      let outUser of OutOfOfficeStatusEmployeeList;
                      let i = index
                    "
                  >
                    <div>
                      <!-- *ngIf="i <= 3" -->
                      <div class="pt-0">
                        <div class="deliveries-box">
                          <div class="deliveries-container d-flex">
                            <div class="media">
                              <img
                                class="img-width-height"
                                style="width: 80px; height: 80px"
                                [src]="outUser.PhotographFileName"
                                alt="..."
                              />
                            </div>
                            <div class="pr-details">
                              <h5>{{ outUser.Employee_Name }}</h5>

                              <div
                                class="carousel slide carousel-fade"
                                data-ride="carousel"
                              >
                                <div class="carousel-inner">
                                  <div class="media">
                                    <span
                                      >Out Time:
                                      {{ outUser.OutEntryTime }}</span
                                    >
                                  </div>
                                  <div class="media">
                                    <span>{{ outUser.DurationTime }} Mins</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="i == 4 && i <= 6" class="pt-2">
                        <div class="deliveries-box">
                          <div class="deliveries-container d-flex">
                            <div class="media">
                              <img class="img-width-height" style="width: 80px; height: 80px"
                                [src]="outUser.PhotographFileName" alt="..." />
                            </div>
                            <div class="pr-details">
                              <h5>{{ outUser.Employee_Name }}</h5>

                              <div class="carousel slide carousel-fade" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="media">
                                    <span>Out Time: {{ outUser.OutEntryTime }}</span>
                                  </div>
                                  <div class="media">
                                    <span>{{ outUser.DurationTime }} Mins</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="i == 8 && i <= 11" class="pt-2">
                        <div class="deliveries-box">
                          <div class="deliveries-container d-flex">
                            <div class="media">
                              <img class="img-width-height" style="width: 80px; height: 80px"
                                [src]="outUser.PhotographFileName" alt="..." />
                            </div>
                            <div class="pr-details">
                              <h5>{{ outUser.Employee_Name }}</h5>

                              <div class="carousel slide carousel-fade" data-ride="carousel">
                                <div class="carousel-inner">
                                  <div class="media">
                                    <span>Out Time: {{ outUser.OutEntryTime }}</span>
                                  </div>
                                  <div class="media">
                                    <span>{{ outUser.DurationTime }} Mins</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </ng-template>
                </owl-carousel-o>
              </div>
            </div>
          </div>

          <div class="feedback-section mt-4" id="feedback">
            <div
              id="feedback"
              class="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div
                  class="carousel-item {{ feedBack.isActive }}"
                  *ngFor="let feedBack of Employee360FeedbackList"
                >
                  <div>
                    <h4>360<sup>0</sup> Feedback</h4>
                  </div>
                  <div class="media">
                    <div class="user-profile">
                      <img [src]="feedBack.PhotographFileName" alt="user" />
                    </div>
                    <div class="media-body">
                      <h5 class="user-name">{{ feedBack.Employee_Name }}</h5>
                      <p>{{ feedBack.Remarks }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="feedback-section mt-4" id="feedback">
            <div
              id="feedback"
              class="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div>
                  <h4>RSS News</h4>
                </div>

                <owl-carousel-o
                  [options]="
                    RSSFeedDataList.length > 1
                      ? customOptionsRSS
                      : customNoslideOptions
                  "
                >
                  <ng-template
                    id="slide-item"
                    carouselSlide
                    *ngFor="let feedBack of RSSFeedDataList; let i = index"
                  >
                    <div class="media">
                      <div class="user-profile">
                        <img [src]="feedBack.ImageURL" alt="user" />
                      </div>
                      <div class="media-body">
                        <h5 class="user-name"></h5>
                        <p>{{ feedBack.Title }}</p>
                      </div>
                    </div>
                  </ng-template>
                </owl-carousel-o>

                <!-- <div
                  class="carousel-item active"
                  *ngFor="let feedBack of RSSFeedDataList"
                >
                  <div class="media">
                    <div class="user-profile">
                      <img [src]="feedBack.ImageURL" alt="user" />
                    </div>
                    <div class="media-body">
                      <h5 class="user-name"></h5>
                      <p>{{ feedBack.Title }}</p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="right-sec mt-0" style="max-width: 100%">
        <div
          class="event-box"
          id="{{
            EmployeeBirthday_AnnyversaryList.length == 0
              ? 'hideCelebration'
              : 'celebration'
          }}"
          style="width: 100%; max-width: 100%"
        >
          <div
            id="eventslider"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                class="carousel-item {{ employee.IsActive }}"
                *ngFor="let employee of EmployeeBirthday_AnnyversaryList"
              >
                <div class="{{ employee.cssClass }} text-center common-box">
                  <div class="top-section">
                    <h5 *ngIf="employee.headerText">Happy</h5>
                    <h2>{{ employee.Title }}</h2>
                    <div class="profile-box mx-auto">
                      <div class="inner-box">
                        <img [src]="employee.employeeImage" alt="..." />
                      </div>
                      <div class="year-box">
                        {{ employee.year
                        }}<sup>{{ employee.OrdinalString }}</sup>
                      </div>
                    </div>
                  </div>
                  <div class="bottom-section">{{ employee.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bottom-widget ml-0"
          id="{{
            EmployeeBirthday_AnnyversaryList.length == 0
              ? 'employeeLeaveStatus'
              : ''
          }}"
          style="max-width: 100%"
        >
          <div
            id="widgetsider"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active" id="leavetoday">
                <div class="onleave-today common">
                  <h4>
                    On Leave Today
                    <button class="d-none" id="onleaveticker_start"></button>
                  </h4>
                  <ul id="onleaveticker">
                    <li *ngFor="let user of OnLeaveTodayEmployeeList">
                      <div class="media align-items-center">
                        <div class="member-profile mr-3">
                          <img [src]="user.PhotographFileName" alt="..." />
                        </div>
                        <div class="media-body">
                          <h5 class="mt-0">{{ user.Employee_Name }}</h5>
                          <p class="mb-0">
                            {{ user.leaveDuration }} ({{ user.leaveType }})
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="carousel-item {{OnLeaveTodayEmployeeList.length == 0 ? 'active' : ''}}" id="outoffice"
                *ngIf="OutOfOfficeStatusEmployeeList.length != 0">
                <div class="outof-office common">
                  <h4>Out of Office</h4>
                  <ul id="outofoffice">
                    <li *ngFor="let outUser of OutOfOfficeStatusEmployeeList">
                      <div class="media align-items-center">
                        <div class="member-profile mr-3">
                          <img [src]="outUser.PhotographFileName" alt="...">
                        </div>
                        <div class="media-body">
                          <h5 class="mt-0">{{outUser.Employee_Name}}</h5>
                          <p class="mb-0 d-flex justify-content-between">
                            <span>Out Time: {{outUser.OutEntryTime}}</span>
                            <span>{{outUser.DurationTime}} Mins</span>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- employeeLeaveStatus -->
</div>
