import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private Repository: RepositoryService,
  ) { }
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  IsLogin = false;
  returnUrl: string;


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {

    this.submitted = true;


    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    // localStorage.setItem('x-auth', 'temptoken');
    // this.router.navigate(['/dashboard']);

    const user = await this.Repository.login(this.loginForm.value);
    if (user != null) {
      this.router.navigate(['/dashboard']);
    }
    else {
      this.loading = false;
      this.IsLogin = true;
    }
  }
}