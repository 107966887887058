import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AuthguardGuard } from './authguard.guard';
import { LoginComponent } from './login/login/login.component';


const routes: Routes = [
  {
      path      : '',
      pathMatch : 'full',
      redirectTo: 'login',
  },
  {
      path      : 'dashboard',
      component : DashboardComponent,
      canActivate: [AuthguardGuard] // !!! HERE !!!

  },
  {
      path      : 'login',
      component : LoginComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
