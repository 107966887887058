import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { CommonService } from './common.service';
// import { config } from '../constant';
import configData from '../../assets/JSON/ConfigData.json';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  constructor(public apiService: ApiServiceService, public common: CommonService, public router: Router) { }

  async GetData(modelname) {
    const response = await this.apiService.get(configData.API_URL + modelname);
    const result = JSON.parse(JSON.stringify(response));
    this.CheckAuth(result);
    return result.Data;
  }

  async GetWeatherData(URL) {
    const response = await this.apiService.getWeather(URL);
    const json = JSON.parse(JSON.stringify(response));

    const result = {
      CityName: json.name,
      weather: {
        description: json.weather[0].description,
        id: json.weather[0].id
      },
      temp: {
        current: Math.floor(((5 * ((json.main.temp) - 32)) / 9)),
        high: Math.floor(json.main.temp_max),
        low: Math.floor(json.main.temp_min)
      }
    };
    return result;
  }

  async login(loginData) {
    // this.common.showLoader();
    const response = await this.apiService.post_without_cache(configData.API_URL + configData.LOGIN, loginData);
    const result = JSON.parse(JSON.stringify(response));
    // this.common.dismissLoader();
    //console.log(result);
    if (result.Status) {
      this.setLoginData(result.Data.userDetails);
      const objUser = result.Data.userDetails;
      // this.common.showSuccess(result.message);
      return objUser;
    } else {
      // this.common.showError(result.message);
      return result;
    }
  }

  CheckAuth(response: any) {
    if (response.statusText == "Unauthorized") {
      this.router.navigate(['/login']);
    }
  }

  setLoginData(userData) {
    console.log(userData);
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('token', userData.TokenValue);
      localStorage.setItem('username', userData.Employee_Name);
      // localStorage.setItem('userId',userData.id);
      localStorage.setItem('email', userData.Email);
      // localStorage.setItem('role_id', userData.role_id);
    }
  }
}
