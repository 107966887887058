import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient) { }

  createAuthorizationHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    var token = localStorage.getItem('token');

    if (token) {
      headers = headers.append('TokenValue', token);
    }
    return headers;
  }

  // public async get(url) {
  //   // const header = this.createAuthorizationHeader();
  //   // const delayType = 'all';
  //   // const request = await this.http.get(url,{withCredentials: true}).toPromise().catch(this.handleErrorObservable);
  //   const request = await this.http.get(url).toPromise().catch(this.handleErrorObservable);
  //   // debugger
  //   // .subscribe(this.extractData);
  //   return request;
  // }

  public async get(url) {
    const header = this.createAuthorizationHeader();
    const delayType = 'all';
    const request = await this.http.get(url, { headers: header }).toPromise().catch(this.handleErrorObservable);
    // .subscribe(this.extractData);
    return request;


    // const request = await this.http.get(url, { headers: header }).toPromise().catch(response => {
    //   if (response.status == 401) {

    //     this.router.navigate(['/login']);
    //   }
    // });
  }

  public async getWeather(url) {
    // const header = this.createAuthorizationHeader();
    // const delayType = 'all';
    const request = await this.http.get(url).toPromise().catch(this.handleErrorObservable);
    // debugger
    // .subscribe(this.extractData);
    return request;
  }

  public async put(url, data) {
    // const headers = this.createAuthorizationHeader();
    // const ttl = 5;
    // const delayType = 'all';
    // const cacheKey = url + JSON.stringify(data);
    const request = await this.http.put(url, data).toPromise().catch(this.handleErrorObservable);
    return request;
  }

  public async post(url, data) {
    const headers = this.createAuthorizationHeader();
    const request = await this.http.post(url, data, { headers: headers }).toPromise().catch(this.handleErrorObservable);
    return request;
  }

  public async post_without_cache(url, data) {
    const headers = this.createAuthorizationHeader();
    //let headers = new HttpHeaders();
    const request = await this.http.post(url, data, { headers: headers }).toPromise().catch(this.handleErrorObservable);
    return request;
  }

  public async delete(url) {
    const headers = this.createAuthorizationHeader();
    return await this.http.delete(url, { headers: headers }).toPromise().catch(this.handleErrorObservable);
  }

  handleErrorObservable(error: Response | any) {
    console.log('error', error);
    var response = error.message || error;
    let responseJson = { is_error: true, message: response,status:error.status,statusText : error.statusText };
    return responseJson;
  }

}
