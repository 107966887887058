<div class="container">
    <div class="row" style="margin-top: 100px;">
        <div class="col-md-6 col-lg-4 mx-auto "
            style="background: #ffffff; padding: 20px;box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);">
            <h2 class="mb-4 text-center">Login</h2>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email is invalid</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Login
                    </button>

                    <div style="margin: 10px;" class="alert alert-danger" *ngIf="IsLogin">
                        <span>Incorrect Username or Password</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>