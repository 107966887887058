import { Component, OnInit } from "@angular/core";
import { Subscription, interval } from "rxjs";
import { OwlOptions } from "ngx-owl-carousel-o";
import { RepositoryService } from "src/app/services/repository.service";
import { CommonService } from "src/app/services/common.service";
import { DatePipe } from "@angular/common";
import configData from "../../../assets/JSON/ConfigData.json";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  title = "VerveEventCalendarDashboard";
  private updateSubscription: Subscription;

  customOptionsRSS: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 7000,
    // navText: false,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    margin: 5,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
      1299: {
        items: 1,
      },
      1300: {
        items: 1,
      },
      1800: {
        items: 1,
      },
    },
  };

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 4000,
    // navText: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    margin: 5,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 4,
      },
      1299: {
        items: 6,
      },
      1300: {
        items: 4,
      },
      1800: {
        items: 4,
      },

      // 0: {
      //   items: 1
      // },
      // 768: {
      //   items: 2
      // },
      // 1200: {
      //   items: 3
      // }
    },
    nav: false,
  };

  customNoslideOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 4000,
    margin: 5,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      992: {
        items: 4,
      },
      1299: {
        items: 4,
      },
      1200: {
        items: 4,
      },
      1800: {
        items: 4,
      },
    },
  };

  //API setting Variables
  WeatherSetting: any;
  OutOfOfficeSetting: any;
  OnleaveTodaySetting: any;
  FeedbackSetting: any;
  BirthdayAnniversarySetting: any;
  ClockSetting: any;
  ProjectsDeliverySetting: any;
  UpcommingEventSetting: any;
  RSSSetting: any;

  // API setting Variable End

  CurrentDayName = "";
  CurrentMonthName = "";
  CurrentDate = "";
  CurrentWeather: any;
  OutOfOfficeStatusEmployeeList = [];
  OnLeaveTodayEmployeeList = [];
  Employee360FeedbackList = [];
  EmployeeBirthday_AnnyversaryList = [];
  VerveDashboardClockList = [];
  VerveCalendarSettings: any;
  ProjectsDeliveryList = [];
  UpcommingEventList = [];
  RSSFeedDataList: any;
  timer;

  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(
    private Repository: RepositoryService,
    private common: CommonService,
    public datepipe: DatePipe
  ) {}

  async ngOnInit() {
    await this.getVerveCalendarSettings();
    await this.getCurrentDateTime();
    await this.onleaveTodayEmployeeList();
    await this.getCurrentCityWeather();
    await this.getOutOfOfficeEmployeeReport();
    await this.employee360FeedbackList();
    await this.employeeBirthday_AnniversaryList();
    await this.getProjectsDeliveryList();
    await this.getUpcommingEventList();
    await this.getRSSFeedData();

    // await this.checkForRefresh();

    await this.setIntervalTimingForAllComponents();
  }

  // async checkForRefresh() {
  //   var now = new Date();
  //   if (now.getHours() == 14 && now.getMinutes() == 0 && now.getSeconds() == 0) {
  //     window.location.reload();
  //   }
  // }

  async setIntervalTimingForAllComponents() {
    if (this.ClockSetting) {
      this.updateSubscription = interval(
        eval(this.ClockSetting.TimeDuration)
      ).subscribe((val) => {
        this.getCurrentDateTime();
      });
    }

    if (this.WeatherSetting) {
      this.updateSubscription = interval(
        eval(this.WeatherSetting.TimeDuration)
      ).subscribe((val) => {
        this.getCurrentCityWeather();
      });
    }

    if (this.OutOfOfficeSetting) {
      this.updateSubscription = interval(
        eval(this.OutOfOfficeSetting.TimeDuration)
      ).subscribe((val) => {
        this.getOutOfOfficeEmployeeReport();
      });
    }

    if (this.OnleaveTodaySetting) {
      this.updateSubscription = interval(
        eval(this.OnleaveTodaySetting.TimeDuration)
      ).subscribe((val) => {
        this.onleaveTodayEmployeeList();
      });
    }

    if (this.BirthdayAnniversarySetting) {
      this.updateSubscription = interval(1000).subscribe((val) => {
        var time = new Date(this.BirthdayAnniversarySetting.TimeToRefresh);
        var now = new Date();
        if (
          now.getHours() == time.getHours() &&
          now.getMinutes() == time.getMinutes() &&
          now.getSeconds() == time.getSeconds()
        ) {
          this.employeeBirthday_AnniversaryList();
        }
      });
    }

    if (this.FeedbackSetting) {
      this.updateSubscription = interval(1000).subscribe((val) => {
        var time = new Date(this.FeedbackSetting.TimeToRefresh);
        var now = new Date();
        if (
          now.getHours() == time.getHours() &&
          now.getMinutes() == time.getMinutes() &&
          now.getSeconds() == time.getSeconds()
        ) {
          this.employee360FeedbackList();
        }
      });
    }

    if (this.ProjectsDeliverySetting) {
      this.updateSubscription = interval(
        eval(this.ProjectsDeliverySetting.TimeDuration)
      ).subscribe((val) => {
        this.getProjectsDeliveryList();
      });
    }

    if (this.UpcommingEventSetting) {
      this.updateSubscription = interval(
        eval(this.UpcommingEventSetting.TimeDuration)
      ).subscribe((val) => {
        this.getUpcommingEventList();
      });
    }

    // if (this.UpcommingEventSetting) {
    //   this.updateSubscription = interval(eval(this.UpcommingEventSetting.TimeDuration)).subscribe(
    //     (val) => {
    //       // this.getUpcommingEventList();
    //     });
    // }
  }

  async getVerveCalendarSettings() {
    this.VerveCalendarSettings = [];
    const settings = await this.Repository.GetData(
      configData.VerveDashboardSetting_API
    );
    if (settings) {
      this.VerveCalendarSettings = settings;

      if (this.VerveCalendarSettings.length > 0) {
        this.VerveCalendarSettings.forEach((setting, index) => {
          if (setting.ComponentName == configData.WeatherAPI) {
            this.WeatherSetting = setting;
          } else if (
            setting.ComponentName == configData.OutOfOfficeEmployeeAPI
          ) {
            this.OutOfOfficeSetting = setting;
          } else if (setting.ComponentName == configData.OnLeaveEmployeeAPI) {
            this.OnleaveTodaySetting = setting;
          } else if (setting.ComponentName == configData.FeedBackAPI) {
            this.FeedbackSetting = setting;
          } else if (
            setting.ComponentName == configData.EmployeeBirthdayAnniversary
          ) {
            this.BirthdayAnniversarySetting = setting;
          } else if (setting.ComponentName == configData.ClockAPI) {
            this.ClockSetting = setting;
          } else if (setting.ComponentName == configData.ProjectsDeliveryAPI) {
            this.ProjectsDeliverySetting = setting;
          } else if (setting.ComponentName == configData.UpcommingEventAPI) {
            this.UpcommingEventSetting = setting;
          } else if (setting.ComponentName == configData.RSSAPI) {
            this.RSSSetting = setting;
          }
        });
      }
    }
  }

  async getCurrentCityWeather() {
    if (this.WeatherSetting) {
      const weatherResponse = await this.Repository.GetWeatherData(
        this.WeatherSetting.APIURL + this.WeatherSetting.URLValue
      );
      this.CurrentWeather = weatherResponse;
    }
  }

  async getCurrentDateTime() {
    // create Date object for current location
    var date = new Date();
    this.CurrentDayName = this.days[date.getDay()];
    this.CurrentMonthName = this.monthNames[date.getMonth()];
    this.CurrentDate = date.getDate().toString();

    // this.VerveDashboardClockList = [];
    if (this.ClockSetting) {
      const VerveDashboardClockList = await this.Repository.GetData(
        this.ClockSetting.APIURL + this.ClockSetting.URLValue
      );
      this.VerveDashboardClockList = VerveDashboardClockList;
    }

    if (this.VerveDashboardClockList.length > 0) {
      // setInterval(() => {
      //   this.VerveDashboardClockList.forEach((clockData, index) => {
      //     const currentDate = new Date(clockData.CurrentTime);
      //     currentDate.setSeconds(currentDate.getSeconds() + 1);
      //     clockData.CurrentTime = currentDate;
      //     clockData.Time = this.common.convertTimeTwoDigitNumber(
      //       clockData.CurrentTime
      //     );
      //   });
      // }, 1000);

      this.VerveDashboardClockList.forEach((clockData, index) => {
        setInterval(() => {
          const currentDate = new Date(clockData.CurrentTime);
          currentDate.setSeconds(currentDate.getSeconds() + 1);
          clockData.CurrentTime = currentDate;
          clockData.Time = this.common.convertTimeTwoDigitNumber(
            clockData.CurrentTime
          );
        }, 1000);
      });
    }
  }

  async getOutOfOfficeEmployeeReport() {
    this.OutOfOfficeStatusEmployeeList = [];

    if (this.OutOfOfficeSetting) {
      const outOfOfficeEmployeeList = await this.Repository.GetData(
        this.OutOfOfficeSetting.APIURL + this.OutOfOfficeSetting.URLValue
      );
      this.OutOfOfficeStatusEmployeeList = outOfOfficeEmployeeList;
    }

    if (this.OutOfOfficeStatusEmployeeList.length > 0) {
      this.OutOfOfficeStatusEmployeeList.forEach((employeeData, index) => {
        var empOutEntryTime = new Date(employeeData.Entry_Time);
        var empEntryOutTime = this.datepipe.transform(empOutEntryTime, "HH:mm");

        employeeData.OutEntryTime = empEntryOutTime;
        employeeData.DurationTime =
          this.common.CalculateHourMinute(empOutEntryTime);
      });
    }
  }

  async onleaveTodayEmployeeList() {
    this.OnLeaveTodayEmployeeList = [];
    if (this.OnleaveTodaySetting) {
      const onLeaveTodayEmployeeList = await this.Repository.GetData(
        this.OnleaveTodaySetting.APIURL + this.OnleaveTodaySetting.URLValue
      );
      this.OnLeaveTodayEmployeeList = onLeaveTodayEmployeeList;
    }
  }

  async employeeBirthday_AnniversaryList() {
    let employeeList: any = [];
    if (this.BirthdayAnniversarySetting) {
      const employeeBirthday_Annyversary = await this.Repository.GetData(
        this.BirthdayAnniversarySetting.APIURL +
          this.BirthdayAnniversarySetting.URLValue
      );
      if (employeeBirthday_Annyversary) {
        employeeList = employeeBirthday_Annyversary;
      }
    }
    // {
    //   IsEventType: 2,
    //   IsActive : true,
    //   cssClass: 'work-anniversary-box',
    //   Title: "Work Anniversary",
    //   employeeImage: '',
    //   year: '2022',
    //   headerText: true,
    //   name: 'Testing',
    //   OrdinalString: '2020'
    // }
    this.EmployeeBirthday_AnnyversaryList = [];
    if (employeeList.length > 0) {
      employeeList.forEach((employee, index) => {
        if (
          new Date(employee.joiningdate).getDate() == new Date().getDate() &&
          new Date(employee.joiningdate).getMonth() == new Date().getMonth()
        ) {
          let joiningData: any = {};

          joiningData.IsEventType = 2;
          joiningData.IsActive = "";
          joiningData.cssClass = "work-anniversary-box";
          joiningData.Title = "Work Anniversary";
          joiningData.employeeImage = employee.PhotographFileName;
          joiningData.year = employee.joiningYear;
          joiningData.headerText = true;
          joiningData.name = employee.employee_name;
          joiningData.OrdinalString = this.common.getOrdinalString(
            employee.joiningYear
          );
          this.EmployeeBirthday_AnnyversaryList.push(joiningData);
        }

        if (
          new Date(employee.birthdate).getDate() == new Date().getDate() &&
          new Date(employee.birthdate).getMonth() == new Date().getMonth()
        ) {
          let birthData: any = {};

          birthData.IsEventType = 1;
          birthData.IsActive = "";
          birthData.cssClass = "birthday-box";
          birthData.Title = "Happy Birthday";
          birthData.employeeImage = employee.PhotographFileName;
          birthData.year = employee.birthYear;
          birthData.headerText = false;
          birthData.name = employee.employee_name;
          birthData.OrdinalString = this.common.getOrdinalString(
            employee.birthYear
          );
          this.EmployeeBirthday_AnnyversaryList.push(birthData);
        }

        if (employee.anniversarydate != null) {
          if (
            new Date(employee.anniversarydate).getDate() ==
              new Date().getDate() &&
            new Date(employee.anniversarydate).getMonth() ==
              new Date().getMonth()
          ) {
            let anniversaryData: any = {};

            anniversaryData.IsEventType = 3;
            anniversaryData.IsActive = "";
            anniversaryData.cssClass = "marriage-anniversary-box";
            anniversaryData.Title = "Wedding Anniversary";
            anniversaryData.employeeImage = employee.PhotographFileName;
            anniversaryData.year = employee.anniversaryYear;
            anniversaryData.headerText = true;
            anniversaryData.name =
              employee.employee_name + " & " + employee.SpouseName;
            anniversaryData.OrdinalString = this.common.getOrdinalString(
              employee.anniversaryYear
            );
            this.EmployeeBirthday_AnnyversaryList.push(anniversaryData);
          }
        }
      });

      this.EmployeeBirthday_AnnyversaryList.sort(function (a, b) {
        return a.IsEventType - b.IsEventType;
      });

      this.EmployeeBirthday_AnnyversaryList[0].IsActive = "active";
    }
  }

  async getProjectsDeliveryList() {
    this.ProjectsDeliveryList = [];
    if (this.ProjectsDeliverySetting) {
      const projectsDeliveryList = await this.Repository.GetData(
        this.ProjectsDeliverySetting.APIURL +
          this.ProjectsDeliverySetting.URLValue
      );
      this.ProjectsDeliveryList = projectsDeliveryList;
    }

    if (this.ProjectsDeliveryList.length > 0) {
      this.ProjectsDeliveryList.forEach((project, index) => {
        var date = new Date(project.ProjectEndDate);
        project.CurrentDayName = this.days[date.getDay()];
        project.CurrentMonthName = this.monthNames[date.getMonth()];
        project.CurrentDate = date.getDate().toString();

        project.OrdinalString = this.common.getOrdinalString(date.getDate());

        project.Employeelist = "";

        project.Attendees.forEach((employee, index) => {
          if (index == 0) {
            employee.isActive = "active";
          } else {
            employee.isActive = "";
          }

          project.Employeelist += "• " + employee.Employee_Name + "\n";
        });
      });
    }
  }

  async getUpcommingEventList() {
    this.UpcommingEventList = [];
    if (this.UpcommingEventSetting) {
      const upcommingEventSettingList = await this.Repository.GetData(
        this.UpcommingEventSetting.APIURL + this.UpcommingEventSetting.URLValue
      );
      this.UpcommingEventList = upcommingEventSettingList;
    }

    if (this.UpcommingEventList.length > 0) {
      this.UpcommingEventList.forEach((event, index) => {
        if (index == 0) {
          event.isActive = "active";
        } else {
          event.isActive = "";
        }

        var date = new Date(event.EventStartDate);
        event.CurrentDayName = this.days[date.getDay()].substring(0, 3);
        event.CurrentMonthName = this.monthNames[date.getMonth()];
        event.CurrentDate = date.getDate().toString();

        var Stime = this.common.convertTimeTwoDigitNumber(event.EventStartDate);
        var start = this.common.timeConvert24to12(Stime);

        if (index == 0) {
          var millisTill10 =
            new Date(event.EventEndDate).getTime() - Date.now();
          if (millisTill10 < 0) {
            millisTill10 += 86400000; // it's after 10am, try 10am tomorrow.
          }
          setTimeout(function () {
            this.getUpcommingEventList();
          }, millisTill10);
        }
        event.StartTime = start.substring(0, 5);
        event.StartTimeA = start.substring(5);

        var Etime = this.common.convertTimeTwoDigitNumber(event.EventEndDate);
        var end = this.common.timeConvert24to12(Etime);
        event.EndTime = end.substring(0, 5);
        event.EndTimeA = end.substring(5);

        event.Location = event.Location;

        event.OrdinalString = this.common.getOrdinalString(date.getDate());

        event.Employeelist = "";

        event.Attendees.forEach((employee, index) => {
          if (index == 0) {
            employee.isActive = "active";
          } else {
            employee.isActive = "";
          }
          event.Employeelist += "• " + employee.Employee_Name + "\n";
        });
      });
    }
  }

  async employee360FeedbackList() {
    this.Employee360FeedbackList = [];
    if (this.FeedbackSetting) {
      const employee360FeedbackList = await this.Repository.GetData(
        this.FeedbackSetting.APIURL + this.FeedbackSetting.URLValue
      );
      this.Employee360FeedbackList = employee360FeedbackList;
    }

    if (this.Employee360FeedbackList.length > 0) {
      this.Employee360FeedbackList.forEach((feedBack, index) => {
        if (index == 0) {
          feedBack.isActive = "active";
        } else {
          feedBack.isActive = "";
        }
        feedBack.header = "feedback";
      });
    }
  }

  async getRSSFeedData() {
    this.RSSFeedDataList = [];
    console.log(this.RSSSetting);
    if (this.RSSSetting) {
      const rssFeedDataList = await this.Repository.GetData(
        this.RSSSetting.APIURL + this.RSSSetting.URLValue
      );
      this.RSSFeedDataList = rssFeedDataList;

      if (this.RSSFeedDataList.length > 0) {
        this.RSSFeedDataList.forEach((RSS, index) => {
          if (index == 0) {
            RSS.isActive = "active";
          } else {
            RSS.isActive = "";
          }
          RSS.header = "RSS";
        });
      }

      // if (this.RSSFeedDataList.length > 0) {
      //   this.RSSFeedDataList.forEach((RSS, index) => {
      //     if (this.Employee360FeedbackList.length == 0) {
      //       if (index == 0) {
      //         RSS.isActive = 'active';
      //       }
      //       else {
      //         RSS.isActive = '';
      //       }
      //     }
      //     RSS.Employee_Name = "";
      //     RSS.PhotographFileName = "../assets/images/RSS.png";
      //     RSS.Remarks = RSS.Title;
      //     RSS.header = 'rss'
      //     this.Employee360FeedbackList.push(RSS);
      //   });
      // }
    }
  }
}
